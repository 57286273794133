import React from 'react';
import { MessageBox } from 'react-chat-elements';
import ReactMarkdown from 'react-markdown';
import './Chat.css';

const renderText = (message) => {
  if (!message.text) {
    return '';
  }

  if (message.sender === 'system') {
    return <div className='loading'></div>
  }

  if (message.sender === 'user') {
    return message.text;
  }

  return <ReactMarkdown>{message.text}</ReactMarkdown>;
};

const getMessageData = (message) => {
  if (message.text) {
    return {};
  }

  return {
    uri: message.s3_image_link,
    width: 200,
    height: 200,
  };
};

const MessageList = ({ messages }) => {
  const messagesEndRef = React.useRef(null);

  React.useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);


  return (
    <div className="message-list">
    {messages.map((message, index) => (
      <MessageBox
        styles={{
            backgroundColor: '#F4F4F4',
            borderRadius: '10px',
            margin: 10,
            maxWidth: '80%',
            padding: message.sender === 'user' ? 15 : 10,
            paddingBottom: message.sender === 'user' ? 15 : 0,
            boxShadow: 'none',
            color: 'rgb(13, 13, 13)',
            fontSize: '18px !important',
            weight: 400,
        }}
        notchStyle={{
          display: 'none',
        }}
        key={message.id || index}
        position={message.sender === 'user' ? 'right' : 'left'}
        type={message.text ? 'text' : 'photo'}
        text={renderText(message)}
        data={getMessageData(message)}
        avatar={message.sender === 'user' ? '' : 'https://robohash.org/assistant.png?set=set3'}
        title={message.sender === 'user' ? '' : 'LIA'}
      />
    ))}
    <div ref={messagesEndRef} />
  </div>
  );
};

export default MessageList;