import React, { useState, useCallback, useEffect, useRef } from 'react';
import 'react-chat-elements/dist/main.css';
import './Chat.css';
import MessageList from './MessageList';
import useWebSocket from 'react-use-websocket';
import { useLocation } from 'react-router-dom';
import { Input, Button } from 'react-chat-elements';

const Chat = () => {
  const [input, setInput] = useState('');
  const [isOpen, setIsOpen] = useState(true);
  const [messages, setMessages] = useState([]);
  const location = useLocation();
  const messagesEndRef = useRef(null);

  const getConversationId = () => {
    const params = new URLSearchParams(location.search);
    return params.get('conversation_id');
  };

  const fetchMessages = async (conversationId) => {
    try {
      if (!conversationId) {
        return null;
      }
      const response = await fetch(`https://liachatbotbackenddemo.shipsy.io/api/chat/conversations/${conversationId}`);
      const data = await response.json();
      if (data && Array.isArray(data.messages)) {
        setMessages(data.messages);
      }
    } catch (error) {
      console.error("Failed to fetch messages:", error);
    }
  };

  const getSocketUrl = useCallback(() => {
    const conversationId = getConversationId();
    return conversationId ? `wss://liachatbotbackenddemo.shipsy.io/api/chat/ws/broadcast/${conversationId}` : null;
  }, [location.search]);

  const { lastJsonMessage, readyState } = useWebSocket(getSocketUrl, {
    onOpen: () => console.log('WebSocket connection established.'),
    onClose: () => console.log('WebSocket connection closed.'),
    shouldReconnect: () => true,
  });

  useEffect(() => {
    const conversationId = getConversationId();
    fetchMessages(conversationId);
  }, [location.search]);

  useEffect(() => {
    if (lastJsonMessage !== null) {
      setMessages((prevMessages) => {
        const oldMessages = prevMessages.filter((message) => message.sender !== 'system');
        const updatedMessages = [...oldMessages, lastJsonMessage];
        const uniqueMessages = updatedMessages.reduce((acc, current) => {
          const x = acc.find(item => item.id === current.id);
          if (!x) {
            acc.push(current);
          }
          return acc;
        }, []);
        return uniqueMessages;
      });
    }
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [lastJsonMessage]);

  const handleSendMessage = async () => {
    const trimmedInput = input.trim();
    if (trimmedInput) {
      const conversationId = parseInt(getConversationId(), 10);

      const newMessage = { text: trimmedInput, sender: 'user', conversation_id: conversationId };
      
      setInput(''); // Clear input field
  
      // Send message to the server via HTTP POST
      try {
        const response = await fetch('https://liachatbotbackenddemo.shipsy.io/api/chat/message', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(newMessage),
        });
  
        if (!response.ok) {
          throw new Error('Failed to send message');
        }
  

  
      } catch (error) {
        console.error("Failed to send message:", error);
      }
    }
  };
  
  return (
    <div className="chat-widget">
      {/* <div className="chat-icon" onClick={() => setIsOpen(!isOpen)}>
        💬
      </div> */}
      {isOpen && (
        <div className="chat-container">
          <div className="chat-header">
          <div className="lia-header"> Shipsy LIA Chatbot </div>
            {/* <span>Chat</span>
            <button className="close-button" onClick={() => setIsOpen(false)}>
              &times;
            </button> */}
          </div>
          <div className="overflow">
            <MessageList messages={messages} />
            <div ref={messagesEndRef} />
          </div>
          <div className="chat-input-container">
  <input
    type="text"
    placeholder="Type a message..."
    value={input}
    onChange={(e) => setInput(e.target.value)}
    onKeyPress={(e) => e.key === 'Enter' && handleSendMessage()}
    className="chat-input"
  />
  <button onClick={handleSendMessage} className="send-button">
    Send
  </button>
</div>


        </div>
      )}
    </div>
  );
};

export default Chat;
